import { Injectable } from '@angular/core';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { ISearchFilter, ISearchFilterItem } from 'modules/search/models/search-filters.models';
import moment from 'moment';

@Injectable()
export class UpdatedByFilterService extends BaseSearchFilterService {
  public override filter: ISearchFilter = {
    name: 'updatedByFilter',
    label: 'Updated Date',
    term: null,
    mode: 'datesChoice',
    multiChoice: false,
    open: false,
    items: [
      { text: 'Updated From Date', term: 'min_updated_date', value: null },
      { text: 'Updated To Date', term: 'max_updated_date', value: null },
    ],

    appearance: 'panel',
    displayLimit: 10,
    visible: false,
  };

  public override representItem(item: ISearchFilterItem): string {
    return `${item.text}: ${moment(item.value).format('MM/DD/YYYY')}`;
  }

  protected override initSelectedItems(): void {
    const conditions = new Map<string, Set<string>>(
      this.filter.items.map((i) => [i.term, super.getConditions(i.term)]),
    );

    this.filter.items.forEach((item) => {
      const [value] = conditions.get(item.term);

      item.value = value;
      item.selected = !!value;
    });

    this.filter.selectedItems = this.filter.items.filter((i) => i.selected);
    this.filter.visible = this.setVisibility();
  }
}
