import { Injectable } from '@angular/core';
import { ISearchFilter } from 'modules/search/models/search-filters.models';
import { UpdatedByFilterService } from './updated-by-filter.service';

@Injectable()
export class ScheduleByFilterService extends UpdatedByFilterService {
  public override filter: ISearchFilter = {
    name: 'scheduleByFilter',
    label: 'Schedule Date',
    term: null,
    mode: 'datesChoice',
    multiChoice: false,
    open: false,
    items: [
      { text: 'From Date', term: 'min_start_date', value: null },
      { text: 'To Date', term: 'max_start_date', value: null },
    ],

    appearance: 'panel',
    displayLimit: 10,
    visible: false,
  };
}
