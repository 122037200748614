import { Injectable } from '@angular/core';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { GlobalConfig } from 'core/environment';
import { UrlParamService } from 'core/navigation';
import { EnvironmentService } from 'core/services';
import { ISearchFilter } from 'modules/search/models/search-filters.models';

@Injectable()
export class CourseRatingFilterService extends BaseSearchFilterService {
  public override filter: ISearchFilter = {
    name: 'courseRatingFilter',
    label: 'Course Rating',
    term: 'rating',
    mode: 'singleChoice',
    multiChoice: false,
    open: false,
    items: [
      { text: '', value: '4' },
      { text: '', value: '3' },
      { text: '', value: '2' },
      { text: '', value: '1' },
    ],

    appearance: 'panel',
    displayLimit: 10,
    visible: true,
  };

  constructor(
    urlService: UrlParamService,
    private globalConfig: GlobalConfig,
    private environmentService: EnvironmentService,
  ) {
    super(urlService);
  }

  public init(): void {
    this.filter.disabled =
      !this.environmentService.userSettings?.course_rating_available ||
      !this.globalConfig.settings?.courseDetails?.reviewsVisible;
  }
}
