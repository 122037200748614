import { Injectable } from '@angular/core';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { CurrentUserService } from 'core/authorization';
import { UrlParamService } from 'core/navigation';
import { GroupService } from 'modules/group/services/group.service';
import { ISearchFilter } from 'modules/search/models/search-filters.models';

@Injectable()
export class SingleGroupFilterService extends BaseSearchFilterService {
  public override filter: ISearchFilter = {
    name: 'singleGroupFilter',
    label: 'Affiliate',
    term: 'group_id',
    mode: 'singleChoice',
    multiChoice: false,
    open: false,
    items: [],

    appearance: 'panel',
    displayLimit: 10,
    visible: true,
  };

  constructor(
    urlService: UrlParamService,
    private groupService: GroupService,
    private currentUser: CurrentUserService,
  ) {
    super(urlService);
  }

  public init(): void {
    const user = this.currentUser.get();

    this.filter.disabled = user.anonymous;
  }

  public load(): void {
    this.groupService.getStates().subscribe((states) => {
      this.filter.items = states.map(({ id, name }) => ({ id, text: name, term: 'group_id', value: id.toString() }));

      this.initSelectedItems();
    });
  }
}
