import { Injectable } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { UrlParamService } from 'core/navigation';
import { orderBy } from 'lodash';
import { TrainingCategorySearchService } from 'modules/course/common/external/services/training-category-search.service';
import { ISearchFilter, ISearchFilterItem } from 'modules/search/models/search-filters.models';

@Injectable()
export class TrainingCategoriesFilterService extends BaseSearchFilterService {
  public override filter: ISearchFilter = {
    name: 'trainingCategoriesFilter',
    label: 'Training Categories',
    term: 'training_category',
    mode: 'multiChoice',
    multiChoice: true,
    open: false,
    items: [],

    appearance: 'panel',
    displayLimit: 10,
    visible: false,
  };

  private sessionKey: string;

  constructor(
    urlService: UrlParamService,
    private currentUser: CurrentUserService,
    private searchService: TrainingCategorySearchService,
  ) {
    super(urlService);
  }

  public init(): void {
    const user = this.currentUser.get();

    this.sessionKey = `trainingCategories.${user.id}.${this.searchService.mode}`;
  }

  public load(): void {
    const trainingCategories = JSON.parse(sessionStorage.getItem(this.sessionKey)) as ISearchFilterItem[];

    if (!trainingCategories) {
      this.searchService.search({ active: true }).subscribe((data) => {
        const items = data.items.map<ISearchFilterItem>((i) => ({
          id: i.id,
          text: i.title,
          value: i.id.toString(),
        }));

        this.filter.items = orderBy(items, 'text');
        super.initSelectedItems();

        sessionStorage.setItem(this.sessionKey, JSON.stringify(this.filter.items));
      });
    } else {
      this.filter.items = trainingCategories;
    }
  }
}
