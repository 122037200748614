import { Injectable } from '@angular/core';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { UrlParamService } from 'core/navigation';
import { ICompetency, ICompetencyNode } from 'modules/competency/models/competency.model';
import { ISearchFilter } from 'modules/search/models/search-filters.models';
import { CompetencyService } from '../../../../competency/services/competency.service';

@Injectable()
export class CompetencyNodeFilterService extends BaseSearchFilterService {
  public override filter: ISearchFilter = {
    name: 'competencyNodeFilter',
    label: 'Competencies and Capabilities',
    term: 'competency_node_id',
    mode: 'popupMultiChoice',
    combineLabels: true,
    multiChoice: true,
    popupOptions: {
      component: 'competencyFilter',
    },
    open: false,
    items: [],

    appearance: 'panel',
    displayLimit: 10,
    visible: true,
  };

  constructor(
    urlService: UrlParamService,
    private competencyService: CompetencyService,
  ) {
    super(urlService);
  }

  public load(): void {
    this.competencyService.getStructure().subscribe((structure) => {
      this.filter.data = { competencies: structure };
      this.filter.items = this.transform(structure).map(({ id, title }) => ({ id, text: title, value: id.toString() }));

      this.initSelectedItems();
    });
  }

  public override represent(): string {
    return this.filter.selectedItems.length + ' Competencies & capabilities Selected';
  }

  private transform(nodes: ICompetencyNode[] | ICompetency[], level = 0): ICompetencyNode[] {
    const children = nodes.flatMap((node: ICompetencyNode | ICompetency) => node.nodes);

    if (children.length > 0) {
      return children.concat(this.transform(children, level + 1));
    }

    return [];
  }
}
