import { Injectable } from '@angular/core';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { ISearchFilter } from 'modules/search/models/search-filters.models';

@Injectable()
export class CommonSearchFilterService extends BaseSearchFilterService {
  public override filter: ISearchFilter = {
    name: 'commonFilter',
    label: 'Common Filters',
    term: null,
    mode: 'multiChoice',
    multiChoice: true,
    open: false,
    items: [
      { text: 'Free Courses', term: 'max_list_price', value: '0' },
      { text: 'Recently Reviewed', term: 'last_reviewed', value: '3months' },
      { text: 'Certificate Available', term: 'course_certificate_available', value: 'true' },
    ],

    appearance: 'panel',
    displayLimit: 10,
    visible: true,
  };

  protected override initSelectedItems(): void {
    const conditions = new Map<string, Set<string>>(
      this.filter.items.map((i) => [i.term, super.getConditions(i.term)]),
    );

    this.filter.items.forEach((item) => {
      item.selected = conditions.get(item.term)?.has(item.value.toString());
    });

    this.filter.selectedItems = this.filter.items.filter((i) => i.selected);
    this.filter.visible = this.setVisibility();
  }
}
