import { Injectable } from '@angular/core';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { CurrentUserService } from 'core/authorization';
import { UrlParamService } from 'core/navigation';
import { ISearchFilter, ISearchFilterItem } from 'modules/search/models/search-filters.models';
import moment from 'moment';

@Injectable()
export class DistanceSearchFilterService extends BaseSearchFilterService {
  public dependent?: ISearchFilter;
  public override filter: ISearchFilter = {
    name: 'distanceFilter',
    label: 'Distance',
    term: null,
    mode: 'numberChoice',
    multiChoice: false,
    open: false,
    items: [{ text: 'Miles', term: 'distance', value: null }],

    appearance: 'panel',
    displayLimit: 10,
    visible: false,
  };

  constructor(
    urlService: UrlParamService,
    private currentUser: CurrentUserService,
  ) {
    super(urlService);
  }

  public init(): void {
    const user = this.currentUser.get();

    this.filter.disabled = user.anonymous;
  }

  public load(): void {
    if (!this.filter.disabled && this.filter.associateWith?.filter) {
      const dependents = this.filter.associateWith.filter.associateWith.dependents;

      this.dependent = dependents?.find((i) => i.name === 'scheduleByFilter');
      this.filter.disabled = !this.dependent;
    }
  }

  public override applyFilter(value: string, term?: string): boolean {
    if (this.dependent && !this.dependent.selectedItems.find((i) => i.term === 'min_start_date')) {
      return super.applyItems([
        { term, text: '', value },
        { term: 'min_start_date', text: '', value: moment().startOf('day').format('YYYY-MM-DD') },
      ]);
    }

    return super.applyFilter(value, term);
  }

  public override representItem(item: ISearchFilterItem): string {
    return `Within: ${item.value + (Number(item.value) > 1 ? ' miles' : ' mile')}`;
  }

  protected override initSelectedItems(): void {
    const conditions = new Map<string, Set<string>>(
      this.filter.items.map((i) => [i.term, super.getConditions(i.term)]),
    );

    this.filter.items.forEach((item) => {
      const [value] = conditions.get(item.term);

      item.value = value;
      item.selected = !!value;
    });

    this.filter.selectedItems = this.filter.items.filter((i) => i.selected);
    this.filter.visible = this.setVisibility();
  }
}
