import { Injectable } from '@angular/core';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { UrlParamService } from 'core/navigation';
import { CourseService } from 'modules/course/common/services/course.service';
import { ISearchFilter } from 'modules/search/models/search-filters.models';

@Injectable()
export class CourseLanguageFilterService extends BaseSearchFilterService {
  public override filter: ISearchFilter = {
    name: 'courseLanguageFilter',
    label: 'Language',
    term: 'language_id',
    mode: 'multiChoice',
    multiChoice: true,
    open: false,
    items: [],

    appearance: 'panel',
    displayLimit: 10,
    visible: false,
  };

  constructor(
    urlService: UrlParamService,
    protected courseService: CourseService,
  ) {
    super(urlService);
  }

  public load(): void {
    this.courseService.getCourseOptions().subscribe((options) => {
      this.filter.items = options.languages.map((i) => ({ id: i.id, text: i.name, value: i.id.toString() }));

      this.initSelectedItems();
    });
  }
}
